<template>
  <div class="flex items-center gap-4">
    <span
      v-for="metric in statsArray"
      class="flex gap-2 items-center"
      :title="metric.value.separate()"
      @click="emit(metric.name as Metric)"
      :class="{
        'cursor-pointer': hasListener(metric.name),
      }"
    >
      <component
        :is="metric.icon"
        class="text-nami-comi-blue"
        :size="small ? 20 : 24"
      />
      <span
        v-if="!loading"
        class="whitespace-nowrap"
        :class="{
          'text-sm': small,
        }"
      >
        {{ formatNumber(metric.value) }}
      </span>
      <Skeleton v-else :class="small ? 'w-6 !h-[15px]' : 'w-8 !h-[18px]'" />
    </span>
  </div>
</template>

<script setup lang="ts">
import {
  IconBookmark,
  IconEye,
  IconHeart,
  IconMessageDots,
} from "@tabler/icons-vue";
import { useNumberFormatter } from "~/composables/format";
import { useQuery } from "@tanstack/vue-query";

type Metric = "comment" | "view" | "follow" | "like";

interface Props {
  titleId?: string;
  pending?: boolean;
  small?: boolean;
  exclude?: Metric[];
}

const { titleId, pending, exclude } = defineProps<Props>();
const emit = defineEmits<{
  (e: Metric): void;
}>();

const locale = useNuxtApp().$i18n.global.locale;
const formatNumber = useNumberFormatter(locale.value);
const loading = computed(() => pending || !titleId || isPending.value);

const { data, isPending } = useQuery(useTitleStats(computed(() => titleId)));

const statsArray = computed(() => {
  const attrs = data.value?.attributes ?? {
    followCount: 0,
    viewCount: 0,
    commentCount: 0,
    extra: {
      totalChapterReactions: [],
      totalChapterComments: [],
      totalChapterViews: [],
    },
  };

  const consolidatedChapterReactions = Object.values(
    attrs.extra.totalChapterReactions,
  ).reduce((a, b) => a + b, 0);

  const consolidatedChapterViews = Object.values(
    attrs.extra.totalChapterViews,
  ).reduce((a, b) => a + b, 0);

  const consolidatedCommentCounts = Object.values(
    attrs.extra.totalChapterComments,
  ).reduce((a, b) => a + b, 0);

  return [
    { name: "follow", icon: IconBookmark, value: attrs.followCount },
    { name: "like", icon: IconHeart, value: consolidatedChapterReactions },
    {
      name: "view",
      icon: IconEye,
      value: attrs.viewCount + consolidatedChapterViews,
    },
    {
      name: "comment",
      icon: IconMessageDots,
      value: attrs.commentCount + consolidatedCommentCounts,
    },
  ].filter((stat) => !exclude?.includes(stat.name as Metric));
});

const thisInstance = getCurrentInstance();
const hasListener = (name: string) =>
  !!thisInstance?.vnode.props?.["on" + name.capitalize()];
</script>
